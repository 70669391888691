<template>
  <entity-card
    :tabs="tabs"
    :tabs-items="tabsItems"
    :title="item.name"
    :image="image"
    :actions="actions"
    :loading="loading"
    :show-back-btn="canBack"
    @click:close="handleClose"
    @click:back="handleBack"
  >
    <template #header:status-bar>
      <div class="mr-2" :class="{ 'red--text': !item.enabled }">
        {{ item.enabled ? 'Enabled' : 'Disabled' }}
      </div>
    </template>
  </entity-card>
</template>

<script>
import EntityCard from '@/components/entity-card/index.vue';
import { computed, onMounted, provide, toRefs } from '@vue/composition-api';

import { useConnectionMenu } from '@/modules/connections/compositions/connectionMenu';

import { useConnection } from '@/modules/connections/compositions/connection';
import useHistoryStack from '@/compositions/history-stack';

export default {
  name: 'ConnectionCard',
  components: {
    EntityCard
  },
  props: {
    connectionId: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { connectionId } = toRefs(props);

    const { hasPrevRoute: canBack } = useHistoryStack();

    const { item, load, loading } = useConnection(connectionId);

    const image = computed(() => item.value.url || '/img/connection.png');

    provide('entity', item);
    provide('entityId', props.connectionId);

    onMounted(async () => {
      load();
    });

    const tabs = [
      {
        id: 'general',
        title: 'General'
      },
      {
        id: 'forwardingRules',
        title: 'Forwarding rules'
      }
    ];
    const tabsItems = [
      () => import('./general/index.vue'),
      () => import('./forwarding-rules/index.vue')
    ];

    const itemActions = computed(() =>
      useConnectionMenu({ entity: item.value })
    );

    const handleClose = () => {
      emit('stack:close');
    };
    const handleBack = () => {
      emit('stack:back');
    };

    return {
      tabs,
      image,
      actions: itemActions,
      handleClose,
      handleBack,
      canBack,
      tabsItems,
      loading,
      item
    };
  }
};
</script>
