import connectionSchema from '../api/connection.graphql';
import { useLazyQuery, useResult } from '@vue/apollo-composable';
import { prepareObject } from '@/provider/utils';

export function useConnection(connectionId) {
  const { result, load, loading } = useLazyQuery(
    connectionSchema.fetch,
    {
      id: connectionId
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-only'
    }
  );

  const item = useResult(result, {}, data => {
    return prepareObject(data.object);
  });

  return {
    load,
    item,
    loading
  };
}
